<template>
  <div class="messageManagement">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDataList"
      :form-inline="formInline"
      :form-item-arr="searchFormItemArr"
      :reset="false"
    />
    <div class="page-container-table">
      <Table :operation-button-width="200" :item-data="itemData" :list-data="messageManagementData" :operation-button="operationButton" :loading="loading">
        <template #index="{ index }">
          <span>{{ index + 1 }}</span>
        </template>
      </Table>
      <!-- <el-table
      size="small"
      :data="messageManagementData"
      highlight-current-row
      v-loading="loading"
      border
      element-loading-text="拼命加载中"
      style="width: 100%;"
    >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column align="center" prop="custName" label="姓名"></el-table-column>
      <el-table-column align="center" prop="custPhone" label="联系电话"></el-table-column>
      <el-table-column align="center" prop="custCompany" label="公司名称"></el-table-column>
      <el-table-column align="center" prop="custPostion" label="职位"></el-table-column>
      <el-table-column align="center" prop="custQuestion" label="留言内容"></el-table-column>
      <el-table-column align="center" prop="reply" label="处理内容"></el-table-column>
      <el-table-column align="center" prop="state" label="状态" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.state==1">待处理</span>
          <span v-else-if="scope.row.state==2">已处理</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="deletemessageManagement(scope.row.id)">删除</el-button>
          <el-button v-if="scope.row.state==1" @click="processingMessage(scope.row)" size="mini">处理</el-button>
        </template>
      </el-table-column>
    </el-table> -->
      <Pagination :get-data-list="getDataList" :total="total" :in-article="messageManagementData.length" :params="formInline" />
    </div>
  </div>
</template>

<script>
import { getCustMessage, deleteCustMessage, handleCustMessage } from '../../api/websiteManagement'
import Pagination from '../../components/Pagination2.vue'
import Table from '@/components/Table'
import FormSearch from '../../components/FormSearch'
export default {
  components: { Pagination, Table, FormSearch },
  data() {
    return {
      total: 0,
      formInline: {
        pageNum: 1,
        pageSize: 10,
        custName: '',
        custCompany: '',
        state: ''
      },
      messageManagementData: [],
      loading: false,
      itemData: [
        { label: '序号', slotName: 'index', width: 20 },
        { label: '姓名', prop: 'custName' },
        { label: '联系电话', prop: 'custPhone' },
        { label: '公司名称', prop: 'custCompany' },
        { label: '职位', prop: 'custPostion' },
        { label: '留言内容', prop: 'custQuestion' },
        { label: '处理内容', prop: 'reply' },
        { label: '状态', prop: 'state', child: [{ value: 1, label: '待处理' }, { value: 2, label: '已处理' }], width: 50 }
      ],
      operationButton: [
        { bType: 'danger', label: '删除', handleEvent: this.deletemessageManagement },
        { val: 'state', num: 1, bType: 'warning', label: '处理', handleEvent: this.processingMessage }
      ],
      searchFormItemArr: [
        { type: 'input', label: '姓名', value: 'custName' },
        { type: 'input', label: '公司名称', value: 'custCompany' },
        { type: 'select', label: '状态', value: 'state', pLabel: 'name', pValue: 'id', child: [{ name: '已处理', id: '2' }, { name: '待处理', id: '1' }] }
      ]
    }
  },
  methods: {
    // 获取列表
    getDataList(type) {
      if (!type) this.formInline.pageNum = 1
      getCustMessage(this.formInline, res => {
        this.messageManagementData = res.data.pageData
        this.total = res.data.total
      })
    },
    // 回复留言
    processingMessage(row) {
      this.$prompt('请输入回复内容', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '不能为空'
      }).then(({ value }) => {
        handleCustMessage({ id: row.id, reply: value }, res => {
          this.$message.success('处理成功！')
          this.getDataList(true)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },
    // 删除留言
    deletemessageManagement({ id }) {
      this.$confirm('确定要删除此条留言吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteCustMessage(id, () => {
            this.$message.success('删除成功！')
            this.getDataList(true)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}
</style>
